import { useContext, useEffect, useRef } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import DebugDiv from "./components/DebugDiv";
import { PersistContext } from "./data/PersistProvider";
import DownloadApp from "./routes/DownloadApp";
import Game from "./routes/Game";
import Index from "./routes/Index";
import About from "./routes/Index/About";
import Contact from "./routes/Index/Contact";
import LanguageChange from "./routes/LanguageChange";
import DepositLog from "./routes/Index/DepositLog";
import Help from "./routes/Index/Help";
import Privacy from "./routes/Index/Privacy";
import Respgame from "./routes/Index/Respgame";
import Termcond from "./routes/Index/Termcond";
import WithdrawControl from "./routes/Index/WithdrawControl";
import WithdrawLog from "./routes/Index/WithdrawLog";
import InviteWheel from "./routes/InviteWheel";
import PiggyBank from "./routes/PiggyBank";
import MakeMoney from "./routes/MakeMoney";
import Login from "./routes/Login";
import SendRegCode from "./routes/Login/SendRegCode";
import LuckyDraw from "./routes/LuckyDraw";
import Setting from "./routes/Setting";
import ChangePwd from "./routes/Setting/ChangePwd";
import Forgot from "./routes/Setting/Forgot";
import SetNewPwd from "./routes/Setting/SetNewPwd";
import Verify from "./routes/Setting/Verify";
import TaskCenter from "./routes/TaskCenter";
import { SendCMD } from "./utils/HTTPRequest";
import HarvestBreadfruit from "./routes/HarvestBreadfruit";
import WreathOfThorns from "./routes/WreathOfThorns";
import ActivityCenter from "./routes/ActivityCenter";
import { LocalConfig } from "./config";
import OKSpinAnalytics from "./utils/OKSpinAnalytics";
import { SendEvent } from "@/utils/Analytics";

// Translated messages in French with matching IDs to what you declared
const messages = {
  myMessage: "Aujourd'hui, c'est le {ts, date, ::yyyyMMdd}",
};

const locationTitles = {
  "/home": LocalConfig.gameName,
};

/**
 * 映射location对应的title
 * @param {window.location} location
 */
const MapLocationTitles = (location) => {
  let title = LocalConfig.gameName;
  if (typeof locationTitles[location.pathname] !== "undefined") {
    title = locationTitles[location.pathname];
  } else if (location.pathname.startsWith("/c/")) {
    title = LocalConfig.gameName;
  } else {
    title = `${location.pathname.substring(1, 2).toUpperCase()}${location.pathname.substring(2)}`;
    // reaplace "/" to " "
    title = title.replace(/\//g, " ");
    if (title.trim() === "") {
      title = LocalConfig.gameName;
    } else {
      title = `${title} - ${LocalConfig.gameName}`;
    }
  }
  //登录页面，如果reg=1，显示注册
  if (location.pathname === "/login") {
    let query = new URLSearchParams(location.search);
    let reg = query.get("reg");
    if (reg) {
      title = `Register - ${LocalConfig.gameName}`;
    }
  }
  return title;
};

const App = () => {

  const location = useLocation();
  const { getPersist, setPersist } = useContext(PersistContext);
  const bgLoaderTimer = useRef();
  const bgTaskTimer = useRef();
  const bgTaskExecutor = useRef();
  const bgSettings = getPersist("bgSettings");
  //成功的orders
  const successOrder = useRef([]);

  async function loadBgSettings() {
    let query = new URLSearchParams(location.search);
    let channel = query.get("c");
    if (!channel && location.pathname.startsWith("/c/")) {
      channel = location.pathname.substring(3);
    }
    if (getPersist("channel")) {
      channel = getPersist("channel");
    } else if (channel) {
      setPersist("channel", channel);
    }
    //定时关闭hallloader，避免因为bgSettings接口的问题导致一直显示loader
    if (bgLoaderTimer.current) {
      clearTimeout(bgLoaderTimer.current);
      bgLoaderTimer.current = null;
    }
    bgLoaderTimer.current = setTimeout(() => {
      closeHallLoader();
    }, 3000);
    let guest_id = window.guestId ? window.guestId() : "";
    let res = await SendCMD("bgSettings", {
      channel,
      guest_id,
      first_visit_url: localStorage.getItem("first_visit_url"),
      first_visit_time: localStorage.getItem("first_visit_time"),
    });
    console.log("bgSettings res", res);
    if (res && res.settings && res.settings.channel_info && res.settings.channel_info.fb_id) {
      console.log("fb_pixel_id", res.settings.channel_info.fb_id);
      setPersist("fb_pixel_id", res.settings.channel_info.fb_id);
    }
    if (res && res.settings && res.settings.channel_info && res.settings.channel_info.boomplay_id) {
      console.log("boomplay_id", res.settings.channel_info.boomplay_id);
      localStorage.setItem("attribution", "boomplay");
      localStorage.setItem("attribution_id", res.settings.channel_info.boomplay_id);
      let oldAdSource = localStorage.getItem("ad_source");
      if (!oldAdSource) {
        oldAdSource = {};
      } else {
        oldAdSource = JSON.parse(oldAdSource);
      }
      oldAdSource.click_id = query.get("click_id") ? query.get("click_id") : oldAdSource.click_id;
      oldAdSource.click_time = query.get("click_time") ? query.get("click_time") : oldAdSource.click_time;
      oldAdSource.account_id = query.get("account_id") ? query.get("account_id") : oldAdSource.account_id;
      oldAdSource.data_source_id = query.get("data_source_id")
        ? query.get("data_source_id")
        : oldAdSource.data_source_id;
      oldAdSource.ip = query.get("ip") ? query.get("ip") : oldAdSource.ip;
      oldAdSource.user_agent = query.get("user_agent") ? query.get("user_agent") : oldAdSource.user_agent;
      oldAdSource.ad_id = query.get("ad_id") ? query.get("ad_id") : oldAdSource.ad_id;
      oldAdSource.callback_url = query.get("callback_url") ? query.get("callback_url") : oldAdSource.callback_url;
      localStorage.setItem("ad_source", JSON.stringify(oldAdSource));
      setPersist("boomplay_id", res.settings.channel_info.boomplay_id);
    }
    if (res && res.settings && res.settings.channel_info && res.settings.channel_info.mgsky_id) {
      console.log("mgsky_id", res.settings.channel_info.mgsky_id);
      localStorage.setItem("attribution", "mgsky");
      localStorage.setItem("attribution_id", res.settings.channel_info.mgsky_id);
      let oldAdSource = localStorage.getItem("ad_source");
      if (!oldAdSource) {
        oldAdSource = {};
      } else {
        oldAdSource = JSON.parse(oldAdSource);
      }
      oldAdSource.clickid = query.get("clickid") ? query.get("clickid") : oldAdSource.clickid;
      oldAdSource.gaid = query.get("gaid") ? query.get("gaid") : oldAdSource.gaid;
      oldAdSource.utm_source = query.get("utm_source") ? query.get("utm_source") : oldAdSource.utm_source;
      localStorage.setItem("ad_source", JSON.stringify(oldAdSource));
      setPersist("mgsky_id", res.settings.channel_info.mgsky_id);
    }
    if (res && res.settings && res.settings.channel_info && res.settings.channel_info.okspin_id) {
      console.log("okspin_id", res.settings.channel_info.okspin_id);
      localStorage.setItem("attribution", "okspin");
      localStorage.setItem("attribution_id", res.settings.channel_info.okspin_id);
      setPersist("okspin_id", res.settings.channel_info.okspin_id);
    }
    if (res) {
      setPersist("bgSettings", res);
      if (res.abtest_tag) {
        setPersist("abtest_tag", res.abtest_tag);
      }
      if (res.client_show_time) {
        setPersist("client_show_time", res.client_show_time);
      }
      setPersist("recall_act_open", false);
      if (res.recall_act_open && res.recall_act_now && res.recall_act_end_time && res.recall_act_now < res.recall_act_end_time) {
        setPersist("recall_act_open", res.recall_act_open);
      }
    }
    closeHallLoader();
  }

  const boomPlayId = getPersist("boomplay_id");
  const mgskyId = getPersist("mgsky_id");
  const okspinId = getPersist("okspin_id");

  //关闭loader
  const closeHallLoader = () => {
    let loader = document.getElementById("hall_loader");
    if (loader) {
      loader.style.display = "none";
      loader.parentNode.removeChild(loader);
    }
  };

  //后台任务执行器
  bgTaskExecutor.current = () => {
    console.log("bgTaskExecutor");
    //检查用户的待检查订单发送归因数据的后台任务
    let nowBgwaittingOrders = getPersist("bg_waitting_recharge_orders");

    console.log("nowBgwaittingOrders", nowBgwaittingOrders);
    if (nowBgwaittingOrders && nowBgwaittingOrders.length > 0) {
      let needRemoveOrderIds = [];
      nowBgwaittingOrders.forEach((order) => {
        if (getPersist("user_info") && getPersist("user_info").token) {

          SendCMD("checkRechargeOrder", {
            token: getPersist("user_info").token,
            order_id: order.order_id,
          }).then((res) => {
            if (res.status) {
              if (res.data == "SUCCESS" || res.data == "MANUAL") {
                // if (successOrder.current.indexOf(order.order_id) < 0) {
                //   successOrder.current.push(order.order_id)
                //   SendEvent("recharge", {
                //     items: [
                //       {
                //         productId: "1",
                //         price: "1",
                //         quantity: "#1",
                //       },
                //     ],
                //     transactionId: order.order_id,
                //   });
                // }
                // needRemoveOrderIds.push(order.order_id);
                SendEvent("recharge", {
                  items: [
                    {
                      productId: "1",
                      price: "1",
                      quantity: "#1",
                    },
                  ],
                  transactionId: order.order_id,
                });
                needRemoveOrderIds.push(order.order_id);
              } else if (res.data == "FAILD") {
                // needRemoveOrderIds.push(order.order_id); //失败状态也先不做处理了，等他超时被自然清除
              } else {
                //待支付状态，不做处理
                console.log("checkRechargeOrder", res.data);
              }
            }
          });
        }
        if (Date.now() - order.created_at > 1000 * 30 * 30) {
          //超过30分钟的，就不管了，直接给他清除
          needRemoveOrderIds.push(order.order_id);
        }
      });
      if (needRemoveOrderIds.length > 0) {
        let newBgwaittingOrders = nowBgwaittingOrders.filter((order) => {
          return !needRemoveOrderIds.includes(order.order_id);
        });
        setPersist("bg_waitting_recharge_orders", [...newBgwaittingOrders]);
      }
    }
  }

  useEffect(() => {
    if (!bgSettings) {
      loadBgSettings()
        .then(() => { })
        .catch(() => { });
    }
    bgTaskExecutor.current();
    if (!bgTaskTimer.current) {
      bgTaskTimer.current = setInterval(() => {
        bgTaskExecutor.current();
      }, 10000);
    }
    return () => {
      if (bgLoaderTimer.current) {
        clearTimeout(bgLoaderTimer.current);
        bgLoaderTimer.current = null;
      }
      if (bgTaskTimer.current) {
        clearInterval(bgTaskTimer.current);
        bgTaskTimer.current = null;
      }
    };
  }, []);
  useEffect(() => {
    let fbPixelId = getPersist("fb_pixel_id");
    console.log("fbPixelId", fbPixelId);
    if (fbPixelId) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      fbq("init", fbPixelId);
      fbq("track", "PageView");
      let watchTimes = 0;
      let watchFbpID = setInterval(() => {
        if (++watchTimes > 100) {
          clearInterval(watchFbpID);
        }
        let fbpID = "";
        var value = "; " + document.cookie;
        var parts = value.split("; _fbp=");
        if (parts.length == 2) {
          fbpID = parts.pop().split(";").shift();
          if (fbpID) {
            localStorage.setItem("attribution", "fb");
            localStorage.setItem("attribution_id", fbpID);
            localStorage.setItem("ad_source", fbPixelId);
            clearInterval(watchFbpID);
          }
        } else {
          console.error("cookie", document.cookie);
        }
      }, 300);
    }
  }, [getPersist("fb_pixel_id")]);

  useEffect(() => {
    console.log("boomplay_id", boomPlayId);
    if (boomPlayId) {
      (function (w, d, t, s) {
        if (w.bp_dsp) return;
        w.bp_dsp = function () {
          bp_dsp.token ? bp_dsp.token.apply(bp_dsp, arguments) : bp_dsp.queue.push(arguments);
        };
        bp_dsp.version = "0.0.0";
        bp_dsp.queue = [];
        var dom = d.createElement(t);
        dom.async = true;
        dom.src = s;
        var p = d.getElementsByTagName(t)[0];
        p.parentNode.insertBefore(dom, p);
      })(window, document, "script", "https://ads.najocp.com/boomplay_dsp.min.js");
      bp_dsp("init", boomPlayId);
    }
  }, [boomPlayId]);

  useEffect(() => {
    console.log("mgskyId", mgskyId);
    if (mgskyId) {
      const script = document.createElement("script");
      script.src = `https://s.mgskyads.com/js/tag.js?aa=${mgskyId}`;
      script.async = true; // 异步加载
      // 将script标签添加到body或head中
      document.body.appendChild(script);
      // 清理函数，组件卸载时移除script标签
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [mgskyId]);

  useEffect(() => {
    console.log("okspinId", okspinId);
    if (okspinId) {
      const script = document.createElement("script");
      script.src = `https://s.oksp.in/js/tag.js?aa=${okspinId}`;
      script.async = true; // 异步加载
      script.onload = () => {
        OKSpinAnalytics.GetInstance(okSpinUserInfo);
      };
      // 将script标签添加到body或head中
      document.head.appendChild(script);
      //初始化okspin的事件发送器
      let okSpinUserInfo = {
        visitorId: window.guestId ? window.guestId() : "",
        customerId: "",
      };
      if (getPersist("user_info")) {
        okSpinUserInfo.customerId = getPersist("user_info").rid;
      }
      // 清理函数，组件卸载时移除script标签
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [okspinId]);

  useEffect(() => {
    console.log("location", location);
    let title = MapLocationTitles(location);
    if (document.title != title) {
      document.title = title;
    }
  }, [location]);
  return (
    <div id="app">
      <Switch>
        <Route exact path="/home">
          <Index />
        </Route>
        <Route exact path="/px">
          <Index />
        </Route>
        <Route exact path="/c">
          <Index />
        </Route>
        <Route exact path="/deposit">
          <Index />
        </Route>
        <Route exact path="/promotion">
          <Index />
        </Route>
        <Route exact path="/withdraw">
          <Index />
        </Route>
        <Route exact path="/profile">
          <Index />
        </Route>
        <Route exact path="/invite">
          <Index />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/sendRegCode">
          <SendRegCode />
        </Route>
        <Route exact path="/game">
          <Game />
        </Route>
        <Route exact path="/help">
          <Help />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/languageChange">
          <LanguageChange />
        </Route>
        <Route exact path="/Termcond">
          <Termcond />
        </Route>
        <Route exact path="/Privacy">
          <Privacy />
        </Route>
        <Route exact path="/Respgame">
          <Respgame />
        </Route>
        <Route exact path="/depositLog">
          <DepositLog />
        </Route>
        <Route exact path="/withdrawLog">
          <WithdrawLog />
        </Route>
        <Route exact path="/setting">
          <Setting />
        </Route>
        <Route exact path="/changePwd">
          <ChangePwd />
        </Route>
        <Route exact path="/forgot">
          <Forgot />
        </Route>
        <Route exact path="/verify">
          <Verify />
        </Route>
        <Route exact path="/setNewPwd">
          <SetNewPwd />
        </Route>
        <Route exact path="/withdrawControl">
          <WithdrawControl />
        </Route>
        <Route exact path="/downloadApp">
          <DownloadApp />
        </Route>
        <Route exact path="/luckyDraw">
          <LuckyDraw />
        </Route>
        <Route exact path="/taskCenter">
          <TaskCenter />
        </Route>
        <Route exact path="/InviteWheel">
          <InviteWheel />
        </Route>
        <Route exact path="/PiggyBank">
          <PiggyBank />
        </Route>
        <Route exact path="/MakeMoney">
          <MakeMoney />
        </Route>
        <Route exact path="/harvest">
          <HarvestBreadfruit />
        </Route>
        <Route exact path="/wreath">
          <WreathOfThorns />
        </Route>
        <Route exact path="/ActivityCenter">
          <ActivityCenter />
        </Route>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
      <DebugDiv />
    </div>
  );
};

export default App;
